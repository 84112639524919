import React, { Fragment } from 'react'
import Box from '@mui/system/Box';
import useTheme from '@mui/material/styles/useTheme';
import 'atropos/atropos.css'
import Atropos from "atropos/react";

import pngTreeCameraRawBeige from "../../images/tree-h348-best/tree-cameraRaw-beige-h348.png"

import DropEffect from './dropEffect/DropEffect';
import * as stylesClay from "../../styles/clay.module.css"
import SeparatorAbsSvg2Lazy from './shaping/SeparatorAbsSvg2Lazy';

const defaultAtroposOptions = {
    component: "DropEffect", // "footer", // 'div', //	Atropos main component tag
    // eventsEl:	HTMLElement | string		Pointer events target. If not specified event handlers will be attached to main container (el)
    activeOffset: 50, // Main container offset when activated (on hover), basically means translateZ value
    alwaysActive: false,
    duration: 300, // Transition duration when activated, leave and and during rotate (in ms)
    rotate: true, // whether to rotate container on pointer move (when activated)
    rotateTouch: true, // whether to rotate container on touch move (when activated), rotate must be enabled. Also accepts special values:
    // scroll-y - will not rotate container with initial vertical touch-move (vertical scrolling), basically will not block page vertical scrolling
    // scroll-x - will not rotate container with initial horizontal touch-move (horizontal scrolling), basically will not block page horizontal scrolling
    rotateXMax: 15, // Max rotation along the X-axis (in deg)
    rotateYMax: 15,	// Max rotation along the Y- axis(in deg)
    rotateXInvert: false,	// Inverts rotation along the X-axis (in deg)
    rotateYInvert: false,	// Inverts rotation along the Y-axis (in deg)
    stretchX: 0,	// Move (translate) atropos container along the X-axis on this value (in percentage). E.g. `50` value will move container +-50% of its width. Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
    stretchY: 0,	// Move (translate) atropos container along the Y-axis on this value (in percentage). E.g. `50` value will move container +-50% of its height. Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
    stretchZ: 0,  // Move (translate) atropos container along the Z-axis on this value (in percentage). E.g. `50` value will move container +-50% of its height. Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
    commonOrigin: true,	//Will dynamically set `transform-origin` for all Atropos components with same parent to same point (usually pointer position). Only for multiple Atropos, when multiple Atropos uses same parent `eventsEl` container
    shadow: true,	// Enables shadow
    shadowOffset: 50, //50	// Shadow offset, basically shadow element's translateZ value
    shadowScale: 1, // 1	// Shadow scale factor
    highlight: true,	// Enables highlight
    // scaleClassName	:		Extra class name on atropos-scale element
    // rotateClassName	:		Extra class name on atropos-rotate element
    // innerClassName	:		Extra class name on atropos-inner element
    // onEnter: function Callback function will be fired when Atropos activated(on hover)
    // onLeave: function Callback function will be fired when Atropos deactivated(on pointer out)
    // onRotate: function (x, y)		Callback function will be fired on rotate.As arguments accepts x and y rotation angles
    scaleChildren: "img", //		Elements passed here will be added to atropos-scale element
    // rotateChildren: "img", //	Elements passed here will be added to atropos-rotate root element
}

const cssFilters = [
    "none",
    "brightness(0.5)",
    "contrast(0.5)",
    "hue-rotate(45deg)",
    "invert(1)",
    "saturate(0.5)",
]

// Control Elements Offsets
// Atropos uses special data- attributes to control elements offset (parallax effect):

// data-atropos-offset - controls element offset/translate in percentage.

// For example:
// data-atropos-offset="5" means that element will move 5% of its size on max rotate angles.
// data-atropos-offset="-5" means that element will move -5% of its size on max rotate angles.
// Basically if you want the element to appear closer (be in front of the scene), then this value should be positive. And if you want the element to appear in the distance (be behind the scene), then this value should be negative.

// data-atropos-opacity - controls element opacity.
// For example:
// data-atropos-opacity="0;1" - means element's opacity will change from 0 (on initial state) and up to 1 (on max rotate angles).
// data-atropos-opacity="0.1;0.8" - means element's opacity will change from 0.1 (on initial state) and up to 0.8 (on max rotate angles).
// data-atropos-opacity="1;0" - means element's opacity will change from 1 (on initial state) and down to to 0 (on max rotate angles).


const sxWrap = {
    "& .atropos-myDivs": {
        height: "700px",
        "& .atropos-inner": {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            perspective: '1000px',
            transformStyle: 'preserve-3d',
            "& .drop": {
                position: 'absolute',

            },
            "& .wrapClay": {
                textAlign: 'center',
                width: 'fit-content',
            },
        },

        "& .layer": {
            position: 'absolute',
            width: '110%',
            height: '110%',
            padding: "20px",
            objectFit: 'contain',
            display: "contents",
            zIndex: '1',
            transformStyle: 'preserve-3d',
            pointerEvents: 'none',
            maxWidth: 'none',
        },
        "& img": {
            objectFit: "none",
            display: 'block',
            zIndex: '1',
            transformStyle: 'preserve-3d',
            pointerEvents: 'none',
            maxWidth: 'none',
            height: 'inherit',
        },
    }
}

const dropsNumber = 5

/** AtroposEffectTree
 *used Atropos js library = touch-friendly 3D parallax hover effects
 combined with images of tree.png
 */
const AtroposEffectTree = () => {
    const theme = useTheme()

    return (
        <Box sx={sxWrap} className="container">
            <Atropos
                className="atropos-myDivs"
                shadow={false}
            >
                {Array(dropsNumber).fill(0).map((v, i) => {
                    const filter = cssFilters[ i ]
                    const rr = i % 3
                    let gradientBgColor
                    switch (rr) {
                        case 0:
                            gradientBgColor = theme.palette.primary.main
                            break;
                        case 1:
                            gradientBgColor = theme.palette.primary2.main
                            break;
                        default:
                            gradientBgColor = theme.palette.secondary2.main

                            break;
                    }
                    return (
                        <Fragment key={`frag-${i}`}>
                            <SeparatorAbsSvg2Lazy
                                name="sepNormalRelDblFullHorizontal1"
                                passedControllingInViewport={false}
                                key={`sepAtro-${i}`}
                                optionsSep={{
                                    gradientBgColor: gradientBgColor,
                                    fillColors: [ theme.palette.success.main, theme.palette.secondary.main, gradientBgColor ],
                                    dPathOrderNotIn: [ 0, 1 ],
                                    heightsWanted: [ 8, 16, 32, 48, 64 ],
                                    styleSvg: {
                                        backgroundBlendMode: 'hard-light',
                                    }
                                }}
                                data-atropos-offset={`${i * 10}`}
                            />
                            <div
                                className="layer"
                                data-atropos-offset={`${i * 10}`}
                                style={{
                                    width: "500px",
                                    height: "500px",
                                    borderRadius: '50%',
                                }}
                                key={`layer-${i}`}
                            >
                                <div
                                    className={`wrapClay ${stylesClay.clay}`}
                                >
                                    web 7
                                </div>
                                <DropEffect
                                    data-atropos-offset={`${i * 30}`}
                                    styleDrop={{
                                        height: `${150 + (dropsNumber - i) * 70}px`
                                    }}
                                >
                                    {v}
                                    <img
                                        data-atropos-offset="-2.5"
                                        src={pngTreeCameraRawBeige}
                                        style={{
                                            backgroundBlendMode: 'hard-light',
                                            filter,
                                        }}
                                        alt="" />
                                </DropEffect>
                            </div>
                        </Fragment>
                    );
                })}
            </Atropos>
        </Box>
    )
}

export default AtroposEffectTree